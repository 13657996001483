/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {
    "use strict";
    var data = {
        'action': 'mode_theme_update_mini_cart'
    };
    $.post(
        woocommerce_params.ajax_url,
        data,
        function (response) {
            $('#mini-cart-tb').html(response); // Repopulate the specific element with the new content
        }
    );
}(jQuery));

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                new SimpleBar($('.menu-hauptnavigation-container')[0], {
                    autoHide: true,
                });

                $('ul.nav a').on('click', function (e) {
                    var parentEl = $(this).parent('li:not(".active")');

                    $("ul.nav li").removeClass('active');
                    $('ul.sub-menu').hide();

                    if (parentEl.hasClass('menu-item-has-children')) {
                        e.preventDefault();
                        $(this).next('ul.sub-menu').toggle('fast');
                        parentEl.toggleClass('active');
                    }
                });

                $('.hamburger').on('click', function (e) {
                    e.preventDefault();
                    $(this).toggleClass('is-active');
                    $('aside.nav-sidebar').toggleClass('is-active');
                });

                $(window).on('click', function (e) {
                    if ($('.nav-sidebar').hasClass('is-active') && e.target.tagName.toLowerCase() === 'a' && !$(e.target).parent('li').hasClass('menu-item-has-children')) {
                        $('.nav-sidebar').removeClass('is-active');
                    }
                });

                $('.cart-icon').on('hover', function () {
                    $('#mini-cart-tb').toggleClass('hover');
                });

                $('#mini-cart-tb').on('mouseleave', function () {
                    $('#mini-cart-tb').toggleClass('hover');
                });
            }
        },
        'single_product': {
            finalize: function() {
                $.each($(".single-product select[data-attribute_name]"), function (t, n) {
                    $(n).selectWoo({
                        minimumResultsForSearch: -1,
                        dropdownParent: $(n).closest(".variations")
                    });
                });
            }
        },
        'archive': {
            finalize: function() {
                $.each($("select.orderby"), function (t, n) {
                    $(n).selectWoo({
                        minimumResultsForSearch: -1,
                        dropdownParent: $(n).closest(".woocommerce-ordering")
                    });
                });

                $('.btn-toggle-filter').on('click', function(e) {
                    e.preventDefault();
                    $(this).toggleClass('active');
                    $('.woof_redraw_zone').toggleClass('active');
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                $('.product-slider').slick({
                    infinite: true,
                    dots: true,
                    dotsClass: 'custom-dots',
                    customPaging: function (slider, i) {
                        var slideNumber = (i + 1),
                            totalSlides = slider.slideCount;
                        return '<a class="dot" role="button" title="' + slideNumber + ' of ' + totalSlides + '"></a>';
                    },
                    autoplay: false,
                    fade: true,
                    autoPlaySpeed: 5000,
                    pauseOnHover: true,
                    pauseOnFocus: false,
                    adaptiveHeight: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
